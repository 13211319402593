import React from "react";

interface ResultsProps {
    isValid: boolean;
    resultText: string;
}
const Results = ({isValid, resultText}:ResultsProps) => {
    return (
      <div>
          <div className='font-bold text-xl'>Results</div>
          <div className={'mt-5 border-[1px] p-2 ' +  (isValid? 'bg-green-200 border-green-300 text-green-600':'bg-red-100 border-red-300 text-red-600')}>
              {resultText}
          </div>
      </div>
    );
}

export default Results