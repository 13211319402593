import React from "react";

interface ValidateSSMLLintButtonProps {
    validateText: () => void;
}
const ValidateButton = ({validateText}:ValidateSSMLLintButtonProps) => {
        return (
            <button className="bg-yellow-300 hover:bg-yellow-400 w-[150px] sm:w-[200px] font-bold font-medum my-6 mx-auto py-3" onClick={validateText}>Validate SSML</button>
        );
}

export default ValidateButton