import React from 'react';
import './App.css';
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import SubmitIssue from './components/SubmitIssue';


function App() {

  return (
    <div className="App">
        <NavBar />
        <Hero />
        <SubmitIssue />
    </div>
  );
}

export default App;
