import React, {useState} from "react";
import {xml} from "@codemirror/lang-xml";
import CodeMirror from "@uiw/react-codemirror";
import {Decoration, ViewPlugin} from "@codemirror/view"
import {RangeSetBuilder} from "@codemirror/rangeset";

interface TextAreaProps {
    ssmlText: string;
    onSsmlTextChange: (value: string) => void;
    positionToHighlight: number;
}

const TextArea = ({ssmlText, onSsmlTextChange, positionToHighlight}: TextAreaProps) => {

// Create a decoration for that line
    const lineDecoration = Decoration.line({
        attributes: {
            style: 'background-color: #fbe3e4;'
        }
    });
    const [isModified, setIsModified] = useState(false);

    function highlightLine(view: any) {
        let builder = new RangeSetBuilder();
        if (positionToHighlight > -1 && !isModified) {
            let line = view.state.doc.lineAt(positionToHighlight);
            console.log("positionToHighlight", positionToHighlight)
            builder.add(line.from, line.from, lineDecoration);
        }
        return builder.finish();
    }

    const showHighlight = ViewPlugin.fromClass(
        class {
            decorations: any;

            constructor(view: any) {
                this.decorations = highlightLine(view)

            }

            update(update: any) {
                if (update.docChanged || update.viewportChanged) {
                    this.decorations = highlightLine(update)
                }
            }
        },
        {decorations: (v) => v.decorations}
    );

    const highlightLines = () => {

        return [showHighlight]
    }

    return (
        <CodeMirror
            className="border-[1px] border-[#e1e1e1]"
            value={ssmlText}
            aria-labelledby="SSML input text area"
            height="calc(100vh - 440px)"
            minHeight='380px'
            width='100%'
            extensions={[xml(), highlightLines()]}
            onChange={e => {
                setIsModified(true)
                onSsmlTextChange(e)
            }}
            onBlur = {() => setIsModified(false)}
        />
    )
}

export default TextArea