import React from "react";

const NavBar = () => {
    return (
        <div className='flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4 border-b'>
            <h1 className='w-full text-1xl font-bold'>SSMLLint
                <span className='font-normal'> &nbsp; - The SSML Validator</span>
            </h1>
        </div>
    )
}

export default NavBar