import React from "react";


const SubmitIssue = () => {
    return (
      <div className='flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4 border-t mt-10'>
          <span > Submit a bug report or ideas <a href="https://github.com/SSMLLint/ssmllint-dot-com/issues" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank" rel="noreferrer"> here at Github </a></span> 
      </div>
    );
}

export default SubmitIssue