import React from "react";
import SSMLLint from "./SSMLLint";

const Hero = () => {
    return (
        <div className='text-black'>
            <div className='flex  max-w-[1240px] mx-auto mt-[30px]  '>
                {/*max-w-[1000px] mt-[30px] w-full h-screen mx-auto flex*/}
                <SSMLLint />
            </div>
        </div>
    )
}

export default Hero