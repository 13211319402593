import React, {useState} from "react";
import ssmlCheck from "ssml-check-core";
import TextArea from "./TextArea";
import ValidateButton from "./ValidateButton";
import Results from "./Results";
import Analytics from "analytics";





// @ts-ignore
import * as vkbeautify from 'vkbeautify';
// import beautify from "xml-beautifier";

// // @ts-ignore
// import * as XmlBeautify from "xml-beautify";
// @ts-ignore
import mixpanelPlugin from '@analytics/mixpanel'
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager'


window.Buffer = window.Buffer || require("buffer").Buffer;

const SSMLLint = () => {
    const [ssmlText, setSsmlText] = useState('');
    const [resultText, setResultText] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [positionToHighlight, setPositionToHighlight] = useState(-1)
    const analytics = Analytics({
        app: 'SSMLLint',
        plugins: [
            googleTagManager({
                containerId: 'G-FTXSEDHCMD'
              }),
            mixpanelPlugin({
                token: '294df512de8ec3457ea30c2f83f7ef06'
            })
        ]
    })

    analytics.page();

    const clearText = () => {
        setSsmlText("")
        setResultText("")
    }

    // function getCharacterPositionFromString(input: string, lineNumber: number, columnNumber: number): number {
    //     // Validate input
    //     if (lineNumber < 1 || columnNumber < 1) {
    //         throw new Error("Line and column numbers should be greater than or equal to 1.");
    //     }
    //
    //     // Split the input string into lines
    //     const lines = input.split("\n");
    //
    //     // Calculate total characters until the requested line
    //     let totalCharacters = 0;
    //     for (let i = 0; i < lineNumber - 1; i++) {
    //         totalCharacters += lines[i].length + 1; // Adding 1 for the newline character
    //     }
    //
    //     // Calculate the character position within the line
    //     const lineToCheck = lines[lineNumber - 1];
    //     const columnToCheck = columnNumber - 1; // Adjust to 0-based index
    //     if (columnToCheck > lineToCheck.length) {
    //         throw new Error("Column number exceeds the length of the line.");
    //     }
    //
    //     // Calculate the final character position by adding the column number within the line
    //     return totalCharacters + columnToCheck;
    // }
    // function findNewlineBeforePosition(str: string, position: number): number {
    //     // Check if the given position is out of bounds
    //     if (position >= str.length || position <= 0) {
    //         throw new Error('Invalid position. Position should be within the string boundaries.');
    //     }
    //
    //     // Start searching for newline character from the given position, moving backward.
    //     let currentIndex = position - 1;
    //     while (currentIndex >= 0) {
    //         if (str[currentIndex] === '\n') {
    //             // Return the position of the newline character found.
    //             return currentIndex;
    //         }
    //         currentIndex--;
    //     }
    //
    //     // If no newline character is found before the given position, return -1.
    //     return -1;
    // }

    function getLineNumber(text: string, position: number): number {
        // Ensure position is within the valid range of the text
        if (position < 0 || position >= text.length) {
            throw new Error('Invalid position: Position is out of range.');
        }

        // Split the text into lines
        const lines = text.split('\n');

        // Iterate through the lines to find the line containing the character at the given position
        let currentLineIndex = 0;
        let currentLineLength = 0;
        for (let i = 0; i < lines.length; i++) {
            const lineLength = lines[i].length + 1; // Adding 1 to account for the newline character
            if (position < currentLineLength + lineLength) {
                // The position is within the current line
                currentLineIndex = i;
                break;
            }
            currentLineLength += lineLength;
        }

        // Return the line number (1-based index)
        return currentLineIndex + 1;
    }

    function StringifyError(errorObj: any, text: string) {
        const lineNumber = getLineNumber(text, errorObj.position);
        if (errorObj.type === 'tag' && errorObj.attribute && errorObj.value) {
            return `The tag "${errorObj.tag}" with attribute "${errorObj.attribute}" has invalid value "${errorObj.value}" at line ${lineNumber} \n`;
        } else if (errorObj.type === 'tag' && errorObj.attribute) {
            return `The tag "${errorObj.tag}" has invalid attribute "${errorObj.attribute}" at line ${lineNumber}\n`;
        } else if (errorObj.type === 'tag') {
            return `Invalid tag "${errorObj.tag}" at line ${lineNumber}\n`;
        } else {
            return `Unknown error type: ${errorObj.type} at line ${lineNumber}\n`;
        }
    }

    function extractErrorMessage(xmlContent: string): string | null {
        const parserErrorRegex = /<parsererror[^>]*>.*?<div[^>]*>(.*?)<\/div>/is;
        const errorMatch = xmlContent.match(parserErrorRegex);

        if (errorMatch && errorMatch.length === 2) {
            const errorMessage = errorMatch[1].trim();
            return "Parse " + errorMessage;
        }

        return null;
    }

    // function extractErrorMessage(xmlContent: string): string | null {
    //     const errorRegex = /<parsererror[^>]*>.*?error on line (\d+) at column (\d+): (.+?)<\/div>/i;
    //     const match = xmlContent.match(errorRegex);
    //
    //     if (match && match.length === 4) {
    //         const lineNumber = match[1];
    //         const errorMessage = match[3];
    //         return `Parse error on line ${lineNumber}: ${errorMessage}`;
    //     }
    //
    //     return null;
    // }
    const SSMLCheck = (prettifiedText: string) => {
        ssmlCheck.check(prettifiedText, {getPositions: true})
            .then((errors: any) => {
                if (errors) {
                    let results = ""
                    results += StringifyError(errors[0], prettifiedText)
                    setResultText(results)
                    setIsValid(false)
                    setPositionToHighlight(errors[0].position)
                } else {
                    setResultText('Valid SSML')
                    setIsValid(true)
                    setPositionToHighlight(-1)
                    console.log('SSML is clean');
                }
            });
    }
    const ValidateText = (text: string) => {
        // const prettifiedText = new XmlBeautify().beautify(text, {
        //     indent: "    ",  //indent pattern like white spaces
        //     useSelfClosingElement: false
        // })
        const prettifiedText = vkbeautify.xml(text);
        console.log(prettifiedText,"1")
        const errorMessage = extractErrorMessage(prettifiedText);
        console.log(errorMessage)
        if (errorMessage) {
            setResultText(errorMessage)
            setIsValid(false)

            setPositionToHighlight(-1)
            return;
        }
        setSsmlText((ssmlText) => {
            SSMLCheck(prettifiedText)
            return prettifiedText;
        })
        analytics.track('validate SSML', {
            item: ssmlText,
            prettifiedText: prettifiedText,
            isValid: isValid,
            errorMessage: resultText
        })
    };


    return (
        <div className="w-full sm:w-[70%]">
            <TextArea ssmlText={ssmlText} onSsmlTextChange={setSsmlText} positionToHighlight={positionToHighlight}/>
            <div className="flex justify-evenly sm:justify-start">
                <div>
                    <ValidateButton validateText={() => ValidateText(ssmlText)}/>
                </div>
                <div>
                    <button
                        className='bg-gray-100 hover:bg-white border-[1px] w-[150px] sm:w-[200px] ml-[30px] font-bold my-6 mx-auto py-3'
                        onClick={clearText}>Clear
                    </button>
                </div>
            </div>
            {resultText !== '' ? <Results isValid={isValid} resultText={resultText}/> : null}
        </div>
    )
}

export default SSMLLint